import React, { useEffect, useState } from "react";
import Axios from "axios";
import { IDeepLinkingProps } from "./DeepLinkingProps";
import { parseSearchDate } from "../../../services/dates";
import { searchSelector, setSearch } from "../../../redux/slices/Search/search";
import { ISearchPayload } from "../../../@types/ISearchPayload";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { coreGetContent } from "../../../services/core";
import { navigate } from "gatsby";
import { Room } from "../../../models/room";
import IURLSearchParams from "../../../@types/IURLSearchParams";
import { getSearchPayloadStayDates } from "../../../utils/helpers";

const DeepLinking = (props: IDeepLinkingProps) => {
  const dispatch = useAppDispatch();
  const search = useAppSelector((state: any) => state.search);
  const location = props.location;
  const searchString = location?.search || "";
  const searchParams = new URLSearchParams(searchString);
  const queryParams = Object.fromEntries(searchParams as any);
  // type SearchParamsObject = { [key: string]: any };
  const queryParamsLowercase = Object.keys(queryParams).reduce((qp, key) => {
    qp[key.toLowerCase()] = queryParams[key];
    return qp;
  }, {} as IURLSearchParams);
  const [message, setMessage] = useState("");
  const isCorpGroupBookingPage =
    location.pathname.indexOf("corp-group-booking") !== -1;

  useEffect(() => {
    let didCancel = false;

    const source = Axios.CancelToken.source();
    const fetchHotel = async (hotelCode: string, codeType: string) => {
      let fetchedHotel = null;
      let type = null;
      const apiUrl = `/hotels?filter[status][value]=1&filter[${codeType}][value]=${hotelCode}&include=brand_id`;
      const data = await coreGetContent(apiUrl, source);
      if (data.data.length) {
        fetchedHotel = data.data[0].attributes;
        type = data.data[0].type;
      }
      return [fetchedHotel, type];
    };

    (async () => {
      if (searchString) {
        const hotelCode = queryParamsLowercase.hotelcode;
        const crsCode = queryParamsLowercase.crscode;
        const lengthOfStay = queryParamsLowercase.los
          ? queryParamsLowercase.los
          : props.lengthOfStay;
        const leadDays = queryParamsLowercase.ld
          ? queryParamsLowercase.ld
          : props.leadDays;
        const leadCheckinDate = queryParamsLowercase.lc
          ? queryParamsLowercase.lc
          : props.hotelDefaultCheckinDate;
        const isGroupCode = queryParamsLowercase.isgroupcode
          ? queryParamsLowercase.isgroupcode == "true"
          : false;
        const checkinDate = queryParamsLowercase.checkin
          ? parseSearchDate(queryParamsLowercase.checkin)
          : undefined;
        const latitude =
          queryParamsLowercase.latitude && queryParamsLowercase.latitude;
        const longitude =
          queryParamsLowercase.longitude && queryParamsLowercase.longitude;
        const checkoutDate = queryParamsLowercase.checkout
          ? parseSearchDate(queryParamsLowercase.checkout)
          : undefined;
        const groupCode = queryParamsLowercase.groupcode
          ? queryParamsLowercase.groupcode.trim().toUpperCase()
          : null;
        const promoCode = queryParamsLowercase.promocode
          ? queryParamsLowercase.promocode.trim().toUpperCase()
          : null;
        const rateCode = isGroupCode ? groupCode : promoCode;
        // const brandCodes: Array<string> = queryParamsLowercase.brandcodes ? queryParamsLowercase.brandcodes.split(",") : [];
        const ratePlanFilterCode = queryParamsLowercase.rateplanfiltercode
          ? queryParamsLowercase.rateplanfiltercode
          : null;
        const discountCodes = ["AAA", "AARP", "GOV", "PKG"];
        let hotel = [];
        if (hotelCode) {
          hotel = await fetchHotel(hotelCode, "hotel_code");
        }
        if (crsCode) {
          hotel = await fetchHotel(crsCode, "crs_code");
        }
        if (hotelCode && hotel[0] != null) {
          const hotelLink = hotel[0]?.path && hotel[0]?.path.alias;
          if (location.pathname.indexOf(hotelLink) == -1) {
            if (hotelCode) {
              delete queryParamsLowercase.hotelcode;
            }
            const newParams = new URLSearchParams(queryParamsLowercase);
            const updatedQueryString = newParams.toString();
            navigate(`${hotelLink}?${updatedQueryString}#rooms`);
          }
        } else {
          const searchPayload: ISearchPayload = {
            ...search,
            ...{
              searchString: isCorpGroupBookingPage ? "" : search.searchString,
              distance: 50,
            },
          };

          if (crsCode && hotel[0] != null) {
            searchPayload.searchString = hotel[0]?.name;
            searchPayload.searchType = hotel[1];
          }

          // const rooms = queryParamsLowercase.rooms ? parseInt(queryParamsLowercase.rooms) : null;
          if (queryParamsLowercase[`adult`]) {
            const adultsArr = queryParamsLowercase[`adult`].split(",");
            const childrenArr = queryParamsLowercase[`child`]
              ? queryParamsLowercase[`child`].split(",")
              : [];
            const childrenAgeArr = queryParamsLowercase[`childages`]
              ? queryParamsLowercase[`childages`].split(",")
              : [];
            const rooms = adultsArr.length;
            const roomsArray = [];

            if (rooms) {
              for (let i = 0; i < rooms && i < 3; i++) {
                const adults = parseInt(adultsArr[i] || 1) || 1;

                const childrens = parseInt(childrenArr[i] || 0);
                const totalGuests = adults + childrens;
                const childrenAges = [];

                const allRoomChildrenAges = childrenAgeArr[i]
                  ? childrenAgeArr[i].split("|")
                  : [];

                for (let j = 0; j < childrens; j++) {
                  const ageFromUrl = allRoomChildrenAges[j];
                  const childAge =
                    ageFromUrl && ageFromUrl >= 0 && ageFromUrl < 18
                      ? ageFromUrl
                      : "";
                  childrenAges.push(childAge);
                }
                totalGuests <= 8 &&
                  roomsArray.push(new Room(adults, childrens, childrenAges));
              }
            }
            if (roomsArray.length) {
              searchPayload.rooms = roomsArray;
            }
          }

          if (
            checkinDate ||
            checkoutDate ||
            lengthOfStay ||
            leadDays ||
            leadCheckinDate
          ) {
            const { formedCheckin, formedCheckout } = getSearchPayloadStayDates(
              checkinDate,
              checkoutDate,
              lengthOfStay,
              leadDays,
              leadCheckinDate
            );
            if (formedCheckin && formedCheckout) {
              searchPayload.checkin = formedCheckin;
              searchPayload.checkout = formedCheckout;
            }
          }
          if (queryParamsLowercase.searchstring) {
            searchPayload.searchString = decodeURI(
              queryParamsLowercase.searchstring
            );
          }
          if (latitude && longitude) {
            searchPayload.latitude = parseFloat(latitude);
            searchPayload.longitude = parseFloat(longitude);
          }

          if (rateCode !== null) {
            if (isGroupCode) {
              searchPayload.discount = null;
              searchPayload.promotionCode = null;
              searchPayload.groupCode = rateCode;
            } else {
              searchPayload.promotionCode = rateCode;
              searchPayload.discount = null;
              searchPayload.groupCode = null;
            }
          } else if (ratePlanFilterCode !== null) {
            searchPayload.ratePlanFilterCode = ratePlanFilterCode;
          }
          if (!didCancel) {
            dispatch(setSearch(searchPayload));
            if (isCorpGroupBookingPage) {
              const invalidCrsMsg = hotelCode
                ? `<div class="error">Hotel code <span class="code">${hotelCode}</span> is not valid.</div> `
                : "";
              setMessage(
                `${invalidCrsMsg}Please select hotel from search bar above and proceed.`
              );
            }
          }
        }
      }
    })();
    return () => {
      didCancel = true;
      source.cancel("Request cancelled for fetch hotel.");
    };
  }, []);

  return message ? (
    <div role="alert" aria-live="assertive">
      {message}
    </div>
  ) : null;
};

export default DeepLinking;
